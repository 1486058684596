<template>
  <div class="uputstvo_li">
    <!-- OBAVESTENJA-->
    <v-card
      class="ma-4 mx-2 my-4 mx-sm-4 my-sm-6 mx-md-8 my-md-12"
      elevation="5"
    >
      <v-card-title>
        <v-spacer></v-spacer>
        Uputstvo
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text class="px-md-7">
        <p class="ceo-text" style="white-space: pre-wrap">
          Licitacije su u fazi pripreme !!! Kratak opis:
        </p>
        <p class="ceo-text" style="white-space: pre-wrap">
          1. Logovanje će biti moguće preko Facebook ili Google naloga.
          Nažalost, posto Facebook ne dozvoljava logovanje bez SSL sertifikata,
          još uvek nije moguće logovanje korisnika osim ukoliko nisu
          registrovani kao testeri.
        </p>

        <p class="ceo-text" style="white-space: pre-wrap">
          2. Licitacije je moguće postaviti da budu "lične" ili "humanitarne".
          (Samo ako je korisnik logovan)
        </p>
        <p class="ceo-text" style="white-space: pre-wrap">
          3. Prikaz preostalog vremena do kraja licitacije je "real-time".
          (Radi)
        </p>

        <p class="ceo-text" style="white-space: pre-wrap">
          4. Sortiranje će biti vrsti i po grupi artikla ili usluge (Radi)
        </p>
        <p class="ceo-text" style="white-space: pre-wrap">
          5. Klikom na artikal ili uslugu koju želimo da licitiramo otvara se
          prozor sa više detalja.(U pripremi)
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>